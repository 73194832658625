<template>
    <v-card-text>
        <v-layout row wrap>
            <v-flex lg12 xs12 >
                 <v-subheader class="font-weight-black secondary--text">Registro de Curso</v-subheader>
            </v-flex>
            <v-flex lg12 xs12>
                 <v-alert
                    outlined
                    type="info"
                    border="left"
                    :class="$vuetify.breakpoint.xs?'caption':''"
                >
                    {{alertText}}
                </v-alert>
            </v-flex>
        </v-layout>
        <v-form v-model="valid" ref="formRegistroCurso">
            <datos-contacto />
                <v-layout row wrap class="px-2" align-center justify-center>
                    <v-flex xs12 class="text-right">
                        <v-btn 
                            color="primary" 
                            :disabled="!valid" 
                            :loading="loading" 
                            @click="registrarCurso"
                        >
                            <v-icon class="pr-2">mdi-send</v-icon>
                                Registrarme
                        </v-btn>
                    </v-flex>
                </v-layout>
        </v-form>
     </v-card-text>
</template>
<script>
export default {
    props:{
        idCurso:{
            type:Number,
            default:-1
        }
    },
    components:{
        'datos-contacto':()=>import("@/components/DatosContacto")
    },
    data(){
        return{
            alertText:'Antes de registrarte al Curso, debes confirmar tus datos de contacto, los mismos serviran para el envio del certificado',
            valid: true,
            dialog:false,
            loading:false,
            label:'',
            valueEdit:null,
            url:''
        }
    },
    methods:{
        registrarCurso(){
            if(this.$refs.formRegistroCurso.validate() && this.idCurso != -1){
                this.loading=true
                this.$http.post(`${this.$apiUrl}abogado-ciudadano/cursos/${this.idCurso}/registro-curso`).then(response=>{
                    this.$store.commit('curso/addRegistroCurso',response.data.data[0])
                    this.$emit('creado',response.data.data[0])
                     this.loading=false
                }).catch(error=>{
                     this.loading=false
                    console.log(error)
                })

            }
        }
    }
}
</script>
<style>
.required label::after {
  content: " *";
  color: red;
}
</style>

